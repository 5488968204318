//  PROD
export const BASE_API_URL = "https://api-obi1.hnrg.it";
export const BASE_URL = "https://obi1.hnrg.it";

// export const BASE_API_URL = "https://dev-api-obi1-poc.hnrg.it";
// export const BASE_URL = "https://main.d3cjw8ugo6yg8j.amplifyapp.com";

export const LOGIN_API_URL = `${BASE_API_URL}/login`;

export const REFRESH_TOKEN_API_URL = `${BASE_API_URL}/token/refresh`;
