import React, { useEffect, useRef, useState } from 'react';
import StreamPlayer from './stream-player';
import './Feed.css';

const Feed = (props) => {
  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;

  const { streams, ppData } = props;
  const stream = streams;

  const [activeStreamId, setActiveStreamId] = useState();
  const [loading, setLoading] = useState(false);
  const [visibleVideos, setVisibleVideos] = useState([]);
  const [isActive, setIsActive] = useState(false);


  const player = useRef(null);

  useEffect(() => {
    const { ENDED, PLAYING, READY, IDLE } = IVSPlayer.PlayerState;
    const { ERROR, METADATA } = IVSPlayer.PlayerEventType;

    if (!isPlayerSupported) {
      console.warn(
        'The current browser does not support the Amazon IVS player.',
      );

      return;
    }

    const onStateChange = () => {
      const newState = player.current.getState();

      setLoading(newState !== PLAYING && newState !== IDLE);

      if (newState === PLAYING) {
        setIsActive(1);
        console.log('Video Playing');
      } else if (newState === IDLE) {
        setIsActive(0);
        console.log('Video Idle');
      }
    };

    const onError = (err) => {
      console.warn('Player Event - ERROR:', err);
    };

    const onMetadata = (metadata) => {
      if (metadata.type === 'text/plain') {
        const metadataText = metadata.data;
        const position = player.current.getPosition().toFixed(2);
        console.warn('Player Event - METADATA:', metadataText, position);
      }
    };

    player.current = IVSPlayer.create();

    player.current.addEventListener(READY, onStateChange);
    player.current.addEventListener(PLAYING, onStateChange);
    player.current.addEventListener(IDLE, onStateChange);
    player.current.addEventListener(ENDED, onStateChange);
    player.current.addEventListener(ERROR, onError);
    player.current.addEventListener(METADATA, onMetadata);

    return () => {
      player.current.removeEventListener(READY, onStateChange);
      player.current.removeEventListener(PLAYING, onStateChange);
      player.current.removeEventListener(IDLE, onStateChange);
      player.current.removeEventListener(ENDED, onStateChange);
      player.current.removeEventListener(ERROR, onError);
      player.current.addEventListener(METADATA, onMetadata);
    };
  }, [IVSPlayer, isPlayerSupported]);

  const setStream = (id, visible) => {
    const index = visibleVideos.indexOf(id);

    if (index > -1 && visible) return;

    let videos = [...visibleVideos];

    if (visible) {
      videos.push(id);
    } else {
      videos.splice(index, 1);
    }

    setLoading(true);
    setVisibleVideos(videos);
    setActiveStreamId(videos[videos.length - 1]);
  };

  if (!isPlayerSupported) {
    return null;
  }

  return (
    <div className="feed">
      {stream && (
        <StreamPlayer
          key={stream.id}
          active={stream.id === activeStreamId}
          loading={stream.id === activeStreamId && loading}
          player={player.current}
          streamData={stream}
          setStream={setStream}
          isPlaying={isActive}
          ppData={ppData}
        />
      )}
    </div>
  );
};

export default Feed;
