import axios from "axios";
import { format } from "date-fns";
import { BASE_API_URL, LOGIN_API_URL, REFRESH_TOKEN_API_URL } from "./paths";

export const APIClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const APIRefreshClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

type LoginOptions = {
  username: string;
  password: string;
};

// @TODO: if needed organizations modify this type
type TokenResponse = {
  accessToken?: string;
  refreshToken?: string;
  token?: string;
  refresh_token?: string;
};

type TokenResponseAPI = {
  token: string;
  refresh_token: string;
};

export async function login({
  username,
  password,
}: LoginOptions): Promise<TokenResponse> {
  const { data } = await APIClient.post<TokenResponseAPI>(LOGIN_API_URL, {
    email: username,
    password,
  });

  return {
    // instance: ........
    accessToken: data.token,
    refreshToken: data.refresh_token,
  };
}

type RefreshTokenOptions = {
  refreshToken: string;
};

// eslint-disable-next-line no-shadow
export async function refreshToken({
  refreshToken,
}: RefreshTokenOptions): Promise<TokenResponse> {
  try {
    const { data } = await APIRefreshClient.post<TokenResponse>(
      REFRESH_TOKEN_API_URL,
      {
        refresh_token: refreshToken,
      }
    );

    return data;
  } catch (err) {
    console.error("refreshToken - Error: ", err);
  }

  return {
    accessToken: "",
    refreshToken: "",
  };
}

export async function getProfile({ accessToken }): Promise<any> {
  try {
    const response = await APIClient.get(`${BASE_API_URL}/logged_user`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error("getProfile - Error: ", err);
  }
}

export async function getPrograms({ accessToken }): Promise<any> {
  try {
    const response = await APIClient.get(`${BASE_API_URL}/programs`, {
      params: {
        start_date:
          format(new Date(), "yyyy-MM-dd") +
          "T01:00:00.000Z" /*'2021-05-01T00:00:00Z'*/,
        unique: 1,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error("getPrograms - Error: ", err);
  }
}

export async function getProgramDetails({ accessToken, id }): Promise<any> {
  try {
    const response = await APIClient.get(`${BASE_API_URL}/programs/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error("getProgramDetails - Error: ", err);
  }
}

export async function getEpisodeDetails({ accessToken, id }): Promise<any> {
  try {
    const response = await APIClient.get(`${BASE_API_URL}/episode/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (err) {
    console.error("getEpisodeDetails - Error: ", err);
  }
}
