import { useAuth } from 'auth/hooks/useAuth'
import { useEffect } from 'react'
import EpisodesMovieCard from '../../components/EpisodesMovieCard'
import {useParams} from 'react-router-dom'

const ProgramDetails = () => {
    const { id } = useParams();
    const { getProgramDetails, episodesList } = useAuth();

    useEffect(() => {
        getProgramDetails(id);
    }, [getProgramDetails, id])

    return (
        <EpisodesMovieCard title="Episodes" data={episodesList || []} />
    )
}

export default ProgramDetails;
