import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './auth/components/AuthProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
