import { Spinner } from "@chakra-ui/react";
import { FC } from "react";

interface ISLLoader {
  color?: string;
  size?: string;
}

const SLLoader: FC<ISLLoader> = ({ color = "primary", size = "md" }) => {
  return <Spinner color={color} size={size} />;
};

export default SLLoader;
