import { isEmpty } from 'lodash';
import {useCallback, useEffect, useState} from 'react'
import SingleMovieCardsList from '../../components/SingleMovieCard'
import { BASE_API_URL } from "../../auth/api/paths";

const Homepage = () => {
  const [programs, setPrograms] = useState([]);

  const getProgramsList = useCallback(async () => {
    try {
      const ppUrl = `${BASE_API_URL}/free-programs`;
      const response = await fetch(ppUrl);
      const data = await response.json();
      const filteredData = !isEmpty(data.elements.episodes) ? data.elements.episodes?.filter(el => el.id !== null) : []
  
      setPrograms(filteredData);
    } catch(err) {
      console.log('Error::> ', err)
    }
  }, [])

  useEffect(() => {
    getProgramsList()
  }, [getProgramsList])


  return (
      <SingleMovieCardsList title="Programs" data={programs || []} />
  )
}

export default Homepage;
