import { useAuth } from 'auth/hooks/useAuth'
import { useEffect } from 'react'
import SingleMovieCardsList from '../../components/SingleMovieCard'

const HomepagePrivate = () => {
    const { getPrograms, programsList } = useAuth();

    useEffect(() => {
        getPrograms();
    }, [getPrograms])

    return (
        <SingleMovieCardsList title="Programs" data={programsList || []} />
    )
}

export default HomepagePrivate;
