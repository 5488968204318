import { useAuth } from 'auth/hooks/useAuth';
import { isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
// import { Text } from '@chakra-ui/react';
import Feed from '../../components/feed';
import { BASE_API_URL } from "../../auth/api/paths";
// import { moviesFiles } from '../../constants/video-constants';

// const feedJSON = `${process.env.PUBLIC_URL}/feed.json`;

const SingleStreamPage = () => {
    const { id } = useParams();
    const { getEpisodeDetails, episodeDetails } = useAuth();
    // const extractMovieById = moviesFiles?.find(el => el.id === Number(id));
    const extractMovieById = episodeDetails?.id;
    const ppUrl = `${BASE_API_URL}/product_placement_notification/${id}`;
    const [streams, setStreams] = useState(undefined);
    const [ppData, setPPData] = useState([]);

    useEffect(() => {
        if(id){
            getEpisodeDetails(id);
        }
    }, [getEpisodeDetails, id])

    useEffect(() => {
        async function fetchStreams() {
            // const response = await fetch(feedJSON);
            // const data = await response.json();
            setStreams({
                id: extractMovieById,
                videoUrl: episodeDetails?.video_url,
                name: episodeDetails?.name,
            });
        }

        if(!isEmpty(episodeDetails)) {
            fetchStreams();
        }
    }, [extractMovieById, episodeDetails]);

    const fetchPPJSON = useCallback(async () => {
        const response = await fetch(ppUrl);
        const data = await response.json();

        setPPData(data.elements);
    }, [ppUrl]);

    useEffect(() => {
        fetchPPJSON();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {/* <Text>SingleStreamPage: {id}</Text> */}
            <Feed streams={streams} ppData={ppData} />
        </>
    )
}

export default SingleStreamPage;
