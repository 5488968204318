
import { useState, useCallback } from 'react';
import {
    chakra,
    Flex,
    Stack,
    Heading,
    Avatar,
    Box,
    FormControl,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    Button,
    useToast,
    // FormHelperText,
    // Link
} from '@chakra-ui/react'
import { FaUserAlt, FaLock } from "react-icons/fa";
import {useAuth} from '../../auth/hooks/useAuth'
import SLLoader from '../../components/SLLoader';

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const LoginPage = () => {
    const {login} = useAuth();
    const toast = useToast();
    const [loginLoading, setLoginLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({
        username: '',
        password: '',
    });
    const username = data.username;
    const password = data.password;
    const submitEnabled = !!username && !!password;

    const handleShowClick = () => setShowPassword(!showPassword);

    const onLoginFormChange = useCallback(
        (key, val) => {
        setData({
            ...data,
            [key]: val,
        });
        },
        [data],
    );
  
    const onLogin = useCallback(async () => {
        setLoginLoading(true);
        try {
        if (!!username && !!password) {
            await login(username, password);
        }
        } catch (error) {
        toast({
            description: typeof error === 'string' ? error : 'Errore',
            status: 'error',
            position: 'bottom',
            isClosable: true,
            duration: 5000,
        });
        }
        setLoginLoading(false);
    }, [login, password, toast, username]);
  
    return (
        <Flex
        flexDirection="column"
        width="100wh"
        height="100vh"
        backgroundColor="#1A202B"
        justifyContent="center"
        alignItems="center"
        fontFamily="Exo"
        >
        <Stack
            flexDir="column"
            mb="2"
            justifyContent="center"
            alignItems="center"
        >
            <Avatar bg="orange.300" />
            <Heading fontFamily="Exo" color="orange.300">Welcome</Heading>
            <Box minW={{ base: "90%", md: "468px" }}>
            <form>
                <Stack
                spacing={4}
                p="1rem"
                backgroundColor="white"
                boxShadow="md"
                >
                <FormControl>
                    <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<CFaUserAlt color="#1A202B" />}
                    />
                    <Input type="email" placeholder="email address" border="1px solid" borderColor="#1A202B" color="#1A202B" value={username} onChange={(e) => onLoginFormChange('username', e.target.value)} />
                    </InputGroup>
                </FormControl>
                <FormControl>
                    <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        color="#1A202B"
                        children={<CFaLock color="#1A202B" />}
                    />
                    <Input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        border="1px solid" borderColor="#1A202B"
                        color="#1A202B"
                        value={password}
                        onChange={(e) => onLoginFormChange('password', e.target.value)}
                    />
                    <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleShowClick} bg="#1A202B">
                        {showPassword ? "Hide" : "Show"}
                        </Button>
                    </InputRightElement>
                    </InputGroup>
                    {/* <FormHelperText textAlign="right">
                    <Link>forgot password?</Link>
                    </FormHelperText> */}
                </FormControl>
                <Button
                    borderRadius={0}
                    type="submit"
                    variant="solid"
                    colorScheme="orange"
                    width="full"
                    onClick={onLogin}
                    disabled={!submitEnabled || loginLoading}
                >
                    {loginLoading ? <SLLoader color="primary" /> : 'Login'}
                </Button>
                </Stack>
            </form>
            </Box>
        </Stack>
        </Flex>
    );
};

export default LoginPage;