import {
  Box,
  Button,
  Heading,
  Container,
  Text,
  Stack,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router';
import { isAndroid, isIOS } from "react-device-detect";
import { history } from '../../history';
import { BASE_API_URL } from "../../auth/api/paths";

const ThankYouPage = () => {
    const { registration_token } = useParams();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(false);

    const onActivateAccount = useCallback(async () => {
        setLoading(true);
        try {
            const url = `${BASE_API_URL}/finalize_user_registration`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({encrypted_user_token: registration_token})
            });
            await response.json();

            if (isAndroid) {
                const url = "intent://obi1.com/#Intent;scheme=https;package=com.obione;end";

                window.location.replace(url);
            } else if (isIOS) {
                window.location.replace("obi1://");
            }
            // else {
            //     window.location.replace(window.location.origin);
            // }
            
        } catch(e) {
            console.log(e);
            setErrors(true);
        }
        setLoading(false);
    }, [registration_token]);

    useEffect(() => {
        if(registration_token) {
            onActivateAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registration_token])

    if(!loading) {
        return (
            <Container maxW={'3xl'} >
                <Stack
                as={Box}
                textAlign={'center'}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 36 }}>
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Stack>
            </Container>
        )
    }

    if(errors) {
        return (
            <Container maxW={'3xl'} >
                <Stack
                as={Box}
                textAlign={'center'}
                spacing={{ base: 8, md: 14 }}
                py={{ base: 20, md: 36 }}>
                    <Heading
                        fontFamily="Exo"
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        Errors. Contact us!
                    </Heading>
                </Stack>
            </Container>
            
        )
    }

    return (
        <Container maxW={'3xl'} >
            <Stack
            as={Box}
            textAlign={'center'}
            spacing={{ base: 8, md: 14 }}
            py={{ base: 20, md: 36 }}>
            {!errors && !loading && (
                <>
                    <Heading
                        fontFamily="Exo"
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        Your account is now activated! <br />
                        <Text as={'span'} color={'orange.400'}>
                        Enjoy your time with Obi1
                        </Text>
                    </Heading>
                    {/* <Text color={'gray.500'}>
                        Monetize your content by charging your most loyal readers and reward
                        them loyalty points. Give back to your loyal readers by granting
                        them access to your pre-releases and sneak-peaks.
                    </Text> */}
                    <Button
                        alignSelf="center"
                        borderRadius={5}
                        type="submit"
                        variant="solid"
                        fontWeight={600}
                        color='white'
                        width="sm"
                        bg='orange.400'
                        _hover={{
                            bg: 'orange.300',
                        }}
                        onClick={() => history.push('/home')}
                    >
                        Go to Homepage
                    </Button>         
                </>                
            )}
            </Stack>
        </Container>
    )
}

export default ThankYouPage;
