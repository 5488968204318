import React from 'react';
import { useCallback } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import {useAuth} from '../../auth/hooks/useAuth'
import { history } from '../../history';

const Links = ['Homepage'];

const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    rounded='md'
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href='/'>
    {children}
  </Link>
);

const NavBar = () => {
    const { logout, isAuthenticated } = useAuth();

    const onLogin = useCallback(() => {
        history.push('/login')
    }, []);

    const onLogout = useCallback(() => {
        logout();
    }, [logout])

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Flex h={16} alignItems='center' justifyContent='space-between'>
                    <HStack spacing={8} alignItems='center'>
                        {/* <Box>Logo</Box> */}
                        <HStack
                        as={'nav'}
                        spacing={4}
                        display={{ base: 'none', md: 'flex' }}>
                        {Links.map((link) => (
                            <NavLink key={link}>{link}</NavLink>
                        ))}
                        </HStack>
                    </HStack>
                    <Flex alignItems={'center'}>
                        <Stack
                            flex={{ base: 1, md: 0 }}
                            justify='flex-end'
                            direction='row'
                            spacing={6}>
                                {
                                    !isAuthenticated ? (
                                        <Button
                                            display={{ base: 'none', md: 'inline-flex' }}
                                            fontSize='sm'
                                            fontWeight={600}
                                            color='white'
                                            bg='orange.400'
                                            onClick={onLogin}
                                            _hover={{
                                                bg: 'orange.300',
                                            }}>
                                            Sign In
                                        </Button>
                                    ) : (
                                        <Menu>
                                            <MenuButton
                                                as={Button}
                                                rounded='full'
                                                variant='link'
                                                cursor='pointer'
                                                minW={0}>
                                                <Avatar
                                                    size='sm'
                                                    src={
                                                        'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9'
                                                    }
                                                />
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={onLogout}>Logout</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    )
                                }
                            
                        </Stack>
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}

export default NavBar
