import { AuthContext } from "../types/authContext";
import { authContext } from "../utils/authContext";
import { useContext } from "react";

export function useAuth(): AuthContext {
  const ctx = useContext(authContext);

  if (!ctx) {
    throw new Error("useAuth hook must be used inside AuthProvider context");
  }

  return ctx;
}
