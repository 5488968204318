import { Link } from 'react-router-dom';
import { Box, Heading, Image, Stack, } from "@chakra-ui/react";
import { v4 as uuidv4 } from 'uuid';
// import { programsList } from '../../constants'

const EpisodesMovieCard = ({ title = '', data }) => {
  return (
    <Box ml={4} my={2} mt={10}>
      {title !== '' && (
          <Heading fontWeight="700" fontSize="1.4rem" ml="inherit" fontFamily="Exo">
            {title}
          </Heading>
        )
      }
      
      <Stack spacing={2} overflowX="auto" padding={4} isInline>
        {data?.map((program) => (
          <Box key={uuidv4()} w={{ base: "100px", sm: "200px", md: "250px", lg: "350px" }} flexShrink={0}>
            <Link to={`/episode/${program.id}`}>
                <Image
                    src={program.episode_icon_url || program.icon_url}
                    rounded={4}
                    alt={program.name}
                    w="100%"
                    h="300px"
                    objectFit="cover"
                    transition="transform 500ms"
                    _hover={{ transform: "scale(1.09)" }}
                />
            </Link>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}

export default EpisodesMovieCard;
