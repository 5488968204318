import { AuthContext } from "../types/authContext";
import {
  clearAuthTokens,
  getAccessToken,
  storeAuthTokens,
} from "../utils/token";
import { useCallback, useEffect, useState } from "react";
import * as AuthAPI from "../api";
import { isEmpty } from "lodash";

// Provider hook that creates auth object and handles state
export function useProvideAuthContext(): AuthContext {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const [programsList, setProgramsList] = useState([]);
  const [episodesList, setEpisodesList] = useState([]);
  const [episodeDetails, setEpisodeDetails] = useState(undefined);

  const login = useCallback(async (username: string, password: string) => {
    // Call login API
    const { accessToken, refreshToken } = await AuthAPI.login({
      username,
      password,
    });

    if (accessToken) {
      storeAuthTokens(accessToken, refreshToken);
      setIsAuthenticated(true);

      window.location.replace("/reg-home");
    }
  }, []);

  const logout = useCallback(() => {
    // Clear stored tokens
    clearAuthTokens();

    // setUser({});
    setIsAuthenticated(false);
  }, []);

  const getUser = useCallback(async () => {
    const existingUserToken = await getAccessToken();

    const response = await AuthAPI.getProfile({
      accessToken: existingUserToken,
    });

    if (!isEmpty(response)) {
      setUser(response.id);
    }
  }, []);

  const getPrograms = useCallback(async () => {
    const existingUserToken = await getAccessToken();

    const response = await AuthAPI.getPrograms({
      accessToken: existingUserToken,
    });

    if (!isEmpty(response)) {
      setProgramsList(response?.elements?.episodes);
    }
  }, []);

  const getProgramDetails = useCallback(async (id) => {
    const existingUserToken = await getAccessToken();

    const response = await AuthAPI.getProgramDetails({
      accessToken: existingUserToken,
      id,
    });

    if (!isEmpty(response)) {
      setEpisodesList(response?.episodes);
    }
  }, []);

  const getEpisodeDetails = useCallback(async (id) => {
    const existingUserToken = await getAccessToken();

    const response = await AuthAPI.getEpisodeDetails({
      accessToken: existingUserToken,
      id,
    });

    if (!isEmpty(response)) {
      setEpisodeDetails(response);
    }
  }, []);

  // Load user token from SessionStorage if available
  useEffect(() => {
    const retrieveToken = async () => {
      const existingUserToken = await getAccessToken();

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!existingUserToken) {
        setIsAuthenticated(true);
      }

      setLoading(false);
    };

    retrieveToken();
  }, [isAuthenticated]);

  // Return an auth context
  return {
    isAuthenticated,
    isLoading,
    login,
    logout,
    getUser,
    user,
    programsList,
    getPrograms,
    episodesList,
    getProgramDetails,
    getEpisodeDetails,
    episodeDetails,
  };
}
