import React, { useEffect } from 'react';
import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react"
import Homepage from './pages/Homepage';
import SingleStreamPage from './pages/SingleStreamPage';
import jwtDecode from 'jwt-decode';
import NavBar from './components/NavBar';
import theme from './theme';
import ThankYouPage from './pages/ThankYouPage';
import LoginPage from './pages/LoginPage/index';
import { history } from './history';
import {useInterval} from './hooks/useInterval';
import { useAuth } from 'auth/hooks/useAuth';
import { getAccessToken, refreshAuthTokens } from 'auth/utils/token';
import { PrivateRoute } from 'privateRoute';
import HomepagePrivate from 'pages/HomepagePrivate';
import ProgramDetails from 'pages/ProgramDetails';

const App = () => {
	const { isAuthenticated } = useAuth();

  const retrieveToken = async () => {
    const existingUserToken = await getAccessToken();

    if (!!existingUserToken) {
      const decodedToken = jwtDecode(existingUserToken);
      const currentDate = new Date();

      // JWT exp is in seconds
      if (decodedToken?.exp * 1000 < currentDate.getTime()) {
        await refreshAuthTokens();
      }
    }
  };

  useInterval(async () => {
    if (isAuthenticated) {
      await retrieveToken();
    }
  }, 30000); // 1800000 -> 30 min

  useEffect(() => {
    if (isAuthenticated && window.location.pathname === '/login') {
      history.replace('/reg-home');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && window.location.pathname === '/home') {
      history.replace('/reg-home');
    }
  }, [isAuthenticated]);
  
  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <Router history={history}>
          <div>
            <NavBar />

            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Route exact path="/home" component={Homepage} />
              <PrivateRoute exact path="/reg-home" component={HomepagePrivate} />
              <PrivateRoute exact path="/program/:id" component={ProgramDetails} />
              <PrivateRoute exact path="/episode/:id" component={SingleStreamPage} />
              <Route path="/confirm-account/registration_token=:registration_token" component={ThankYouPage} />
              <Route path="/login" component={LoginPage} />
              <Route exact path="/">
                <Redirect to={isAuthenticated ? "/reg-home" : "/home"} />
              </Route>
            </Switch>
          </div>
        </Router>
      </ChakraProvider>
    </div>
  );
};

export default App;
