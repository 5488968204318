import React, { useEffect, useRef, useCallback, useState } from 'react';
// import { Button, Flex, Text, Input, Box } from '@chakra-ui/react';
import { Text, Box } from '@chakra-ui/react';
import Placeholder from '../placeholder';
// import StreamMetadata from '../stream-metadata';
import { isElementInViewport } from '../utils';
// import { VolumeOff, VolumeUp } from '../../../assets/icons';
import './StreamPlayer.css';
import { convertToDuration } from '../../../utils';
import {useAuth} from '../../../auth/hooks/useAuth'
import { BASE_API_URL } from "../../../auth/api/paths";


// const constant = "IUNydWMxNGwh"

const StreamPlayer = (props) => {
  const { isAuthenticated, getUser, user } = useAuth();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { active, loading, player, streamData, setStream, /*isPlaying,*/ ppData } = props;
  const { id, videoUrl, name } = streamData;

  // const [muted, setMuted] = useState(false);

  const videoEl = useRef(null);
  const visibleRef = useRef(false);

  // const [value, setValue] = useState('')
  // const onHandleChange = useCallback((event) => setValue(event.target.value), []);

  // handle case when autoplay with sound is blocked by browser
  useEffect(() => {
    if (!active || loading) return;

    // setMuted(player.isMuted());
  }, [active, loading, player]);

  useEffect(() => {
    if (!active) return;

    player?.pause();

    player?.attachHTMLVideoElement(videoEl.current);
    player?.load(videoUrl);

    player?.play();
    setIsVideoPlaying(true);
  }, [player, active, videoUrl]);

  useEffect(() => {
    const onVisibilityChange = () => {
      const visible = isElementInViewport(videoEl.current);

      if (visible === visibleRef.current) return;

      visibleRef.current = visible;
      setStream(id, visible);
    };

    onVisibilityChange();

    window.addEventListener('scroll', onVisibilityChange);
    window.addEventListener('resize', onVisibilityChange);

    return () => {
      window.removeEventListener('scroll', onVisibilityChange);
      window.removeEventListener('resize', onVisibilityChange);
    };
  }, [id, setStream]);

  // const toggleMute = () => {
  //   const muteNext = !player.isMuted();

  //   player.setMuted(muteNext);
  //   setMuted(muteNext);
  // };

  // const { streamTitle, userAvatar, userColors, userName } = metadata;
  // const { state, startTime } = stream;
  // const { primary, secondary } = userColors;

  useEffect(() => {
    getUser();
  }, [getUser]);

  const onRestartVideo = useCallback(() => {
    player?.attachHTMLVideoElement(videoEl.current);
    player?.load(videoUrl);
    player?.pause();
    setIsVideoPlaying(false);
  }, [player, videoUrl]);

  const onPlayingVideo = useCallback(() => {
    // player?.attachHTMLVideoElement(videoEl.current);
    // player?.load(videoUrl);
    // player?.pause();
    setIsVideoPlaying(true);
  }, []);

  const onSendPushNotification = useCallback(async (data) => {
    try {
      const pushUrl = `${BASE_API_URL}/product_placement_notification`
      return await fetch(pushUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id: user,
          product_placement_notification_id: data.product_placement_id
        }),
      });

    } catch(e) {
      console.log('Error push: ', e)
    }
  }, [user]);

  // const onSendMockedNotification = useCallback(() => {
  //   onSendPushNotification({ product_placement_id: 3 })
  // }, [onSendPushNotification])

  useEffect(() => {
    let interval;
    if (isAuthenticated && isVideoPlaying) {
      interval = setInterval(() => {
        const currentTime = videoEl.current.currentTime.toFixed(1);
        const convertedTime = convertToDuration(currentTime);
        
        ppData.find(o => {
          if (o.send_at === convertedTime.toString()) {
            onSendPushNotification(o);
            return true;
          }
          return false;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [ppData, onSendPushNotification, isAuthenticated, isVideoPlaying]);

  return (
    <div className={`stream-wrapper ${active ? ' stream-wrapper--active' : ''}`}>
      <div className="aspect-16x9" style={{ background: 'black' }}>
        <div className="player-ui">
          <video className="player-video-el" ref={videoEl} playsInline controls onEnded={() => onRestartVideo()} onPlay={() => onPlayingVideo()} onProgress={() => onPlayingVideo()} />
        </div>

        <Placeholder
          avatar="https://www.freeiconspng.com/uploads/load-icon-png-8.png"
          bgColor='black'
          isActive={active}
          playing={active && !loading}
          spinnerColor='yellow'
          // userName="https://www.freeiconspng.com/uploads/load-icon-png-8.png"
        />
      </div>

      {/* <StreamMetadata
        active={active}
        // startTime={startTime}
        // state={state}
        title={name}
        // userAvatar={userAvatar}
        // userName={userName}
      /> */}

      <Box mt="30px">
        <Text fontFamily="Exo" fontWeight="bold" fontSize="20px">{name}</Text>
      </Box>

      {/* <Flex marginTop="50px">
        <Flex flexDirection="column" width="50%">
          {
            value !== atob(constant) ? (
              <>
                <Text paddingBottom="10px">Add password to enable send notifications</Text>
                <Input
                  variant="outline"
                  value={value}
                  onChange={onHandleChange}
                  size="lg"
                  width="100%"
                />
              </>
            ) : (
              <Flex>
                <Button colorScheme="orange" onClick={onSendMockedNotification}>Send mocked notification</Button>
              </Flex>
            )
          }
        </Flex>
      </Flex> */}
    </div>
  );
};

export default StreamPlayer;
